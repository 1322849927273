/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "antd/dist/antd.css";
import {
  Table,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  Typography,
  Button,
  Space,
  Spin,
  Tooltip,
} from "antd";
import { Row, Col } from "react-bootstrap";
import Highlighter from "react-highlight-words";
import { SearchOutlined, LoadingOutlined } from "@ant-design/icons";
import { useContext } from "react";
import NumberFormat from "react-number-format";

import { TransactionsContext } from "../context/TransactionsContext";
import { FilterContext } from "../context/FilterContext";
import { ExportExcel } from "./ExportExcel";
import { UploadFile } from "./Upload";
import SaveAll from "./SaveAll";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: false,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export const TransactionsTable = ({ setReportDate,userPermission, setAllRowsFlag }) => {
  const { filterState } = useContext(FilterContext);
  const { state, dispatch } = useContext(TransactionsContext);
  // console.log("Transactions Data::", state);
  let transformedData, data, columns, reportDate, totalRecords;
  columns = state.columns;
  // console.log("data####", state.transactions);
  reportDate = state.reportDate;
  setReportDate(reportDate);
  totalRecords = state.totalRecords;
  if (state.transactions.length > 0) {
    data = state.transactions;
    // console.log("Trans data::", data);
    // console.log("Trans columns::", columns);

    state.transactions = data.map((record) => {
      return {
        key: record["Index"],
        Index: record["Index"],
        Company: record["Company"],
        Account: record["Account"],
        "Posting Date": record["Posting Date"],
        "Value Date": record["Value Date"],
        "Doc. Date": record["Doc. Date"],
        Assignment: record["Assignment"],
        "Ref.Key1": record["Ref.Key1"],
        "Ref.Key2": record["Ref.Key2"],
        "Ref.Key3": record["Ref.Key3"],
        Text: record["Text"],
        Business_Area: record["Business_Area"],
        "Doc. Type": record["Doc. Type"],
        "Posting Key": record["Posting Key"],
        "Doc. Curr": record["Doc. Curr"],
        "Amount-doc. curr.": record["Amount-doc. curr."],
        "Loc. Curr.": record["Loc. Curr."],
        "Amount-local curr.": record["Amount-local curr."],
        "Document No": record["Document No"],
        Clearing: record["Clearing"],
        "User Name": record["User Name"],
        Recs: record["Recs"],
        Owner: record["Owner"],
        CP: record["CP"],
        "Retail/ Partners": record["Retail/ Partners"],
        Ageing: record["Ageing"],
        ABS: record["ABS"],
        Buckets: record["Buckets"],
        Formula: record["Formula"],
        "Recs Comments": record["Recs Comments"],
        "Action items of the owner": record["Action items of the owner"],
        "Name of the owner": record["Name of the owner"],
        "Escalation 1": record["Escalation 1"],
        "Escalation 2": record["Escalation 2"],
        "Escalation 3": record["Escalation 3"],
        "Escalation 4": record["Escalation 4"],
        Category: record["Category"],
      };
    });
  }
  // console.log("old data::", transformedData);
  const [form] = Form.useForm();

  const [editingKey, setEditingKey] = useState("");

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      Company: "",
      Account: "",
      "Posting Date": "",
      "Value Date": "",
      "Doc. Date": "",
      Assignment: "",
      "Ref.Key1": "",
      "Ref.Key2": "",
      "Ref.Key3": "",
      Text: "",
      Business_Area: "",
      "Doc. Type": "",
      "Posting Key": "",
      "Doc. Curr": "",
      "Amount-doc. curr.": "",
      "Loc. Curr.": "",
      "Amount-local curr.": "",
      "Document No": "",
      Clearing: "",
      "User Name": "",
      Recs: "",
      Owner: "",
      CP: "",
      "Retail/ Partners": "",
      Ageing: "",
      ABS: "",
      Buckets: "",
      Formula: "",
      "Recs Comments": "",
      "Action items of the owner": "",
      "Name of the owner": "",
      "Escalation 1": "",
      "Escalation 2": "",
      "Escalation 3": "",
      "Escalation 4": "",
      Category: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  // console.log("edit key:::", editingKey);

  const cancel = () => {
    setEditingKey("");
  };

  const Save = async (record) => {
    try {
      const row = await form.validateFields();
      // console.log("row::", row);
      // console.log("record::", { ...record, ...row });
      dispatch({ type: "UPDATE", payload: { ...record, ...row } });
      // console.log("update records::", state.updatedRecords);
      setEditingKey("");
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  let [searchState, setSearchState] = useState("");
  searchState = {
    searchText: "",
    searchedColumn: "",
  };
  let searchInput;
  let getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchState.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchState.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchState({ searchText: "" });
  }

  let [filterData, setFilterData] = useState(null);
  const handleTableChange = (pagination, filters, sorter) => {
    setFilterData({
      filterData: filters,
    });
    // console.log("pagination::", pagination);
    // console.log("filters::", filters);
    // console.log("sorter::", sorter);
    // console.log("filter data::", filterData);
  };

  // console.log("transform Data", transformedData);
  // console.log("Trans Columns:", columns);

  if (!userPermission.includes("W") && columns.includes('Index')) {
    let keyItem = columns.indexOf('Index')
    columns.splice(keyItem,1)
    // console.log("Trans Columns in if:",columns)
  }
  // console.log("Trans Columns2:",columns);

  let tbColumnsList;
  if (!tbColumnsList) {
    if (columns && columns.length > 0) {
      tbColumnsList = columns.map((tbcolumn) => {
        let editColumnFlag = false;
        if (
          [
            "Recs Comments",
            "Action items of the owner",
            "Name of the owner",
            "Escalation 1",
            "Escalation 2",
            "Escalation 3",
            "Escalation 4",
            "Category",
          ].includes(tbcolumn)
        ) {
          editColumnFlag = true;
        } else {
          editColumnFlag = false;
        }
        if (["Retail/ Partners"].includes(tbcolumn)) {
          return {
            title: tbcolumn,
            dataIndex: tbcolumn,
            editable: editColumnFlag,
            align: "left",
            ellipsis: {
              showTitle: false,
            },
            filters: [
              { text: "ATM", value: "ATM" },
              { text: "RETAIL", value: "RETAIL" },
              { text: "PARTNERS", value: "PARTNERS" },
              { text: "OVERHEADS", value: "OVERHEADS" },
              { text: "OTHERS", value: "OTHERS" },
            ],
            onFilter: (value, record) => {
              // console.log("filter column::", filterData);
              // console.log("tb column::", tbcolumn);
              // console.log("value::", value);
              // console.log("record::", record);
              return record[tbcolumn].includes(value);
            },
            render(text, record) {
              <Tooltip placement="topLeft" title={text}>
                {text},
              </Tooltip>;
              return {
                props: {
                  style: { color: parseInt(text) < 0 ? "red" : "" },
                },
                children: <div>{text}</div>,
              };
            },
          };
        }
        if (["Amount-doc. curr.", "Amount-local curr."].includes(tbcolumn)) {
          return {
            title: tbcolumn,
            dataIndex: tbcolumn,
            editable: editColumnFlag,
            align: "right",
            ellipsis: {
              showTitle: false,
            },
            ...getColumnSearchProps(tbcolumn),
            render(text, record) {
              <Tooltip placement="topLeft" title={text}>
                {text},
              </Tooltip>;
              return {
                props: {
                  style: { color: parseInt(text) < 0 ? "red" : "" },
                },
                children: (
                  <div>
                    {
                      <NumberFormat
                        value={text}
                        displayType="text"
                        thousandSeparator={true}
                      />
                    }
                  </div>
                ),
              };
            },
          };
        }
        return {
          title: tbcolumn,
          dataIndex: tbcolumn,
          editable: editColumnFlag,
          ellipsis: {
            showTitle: false,
          },
          align: "left",
          ...getColumnSearchProps(tbcolumn),
          render: (text) => (
            <Tooltip placement="topLeft" title={text}>
              {text}
            </Tooltip>
          ),
        };
      });
      if (userPermission.includes("W")) {
        tbColumnsList[0] = {
          title: "Operation",
          dataIndex: "Operation",
          fixed: "left",
          // #1890ff
          render: (_, record) => {
            const editable = isEditing(record);
            return editable ? (
              <span>
                <a
                  href="javascript:;"
                  onClick={() => Save(record)}
                  style={{
                    marginRight: 8,
                    color: "#1890ff",
                  }}
                >
                  Save
                </a>
                <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                  <a style={{ color: "#1890ff" }}>Cancel</a>
                </Popconfirm>
              </span>
            ) : (
              <Typography.Link
                disabled={editingKey !== ""}
                onClick={() => edit(record)}
              >
                Edit
              </Typography.Link>
            );
          },
        };
      } else {
        data = state.transactions;
        delete data.Index
        state.transactions = data
      }
    }
  }
  // console.log("trans column list::", tbColumnsList);

  const mergedColumns =
    tbColumnsList &&
    tbColumnsList.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
    });

  return (
    <>
      <Row>
        <Col>
          <UploadFile
            country={filterState.country}
            businessDate={filterState.businessDate}
          />
        </Col>
        <Col>
          <div style={{ float: "right" }}>
            <Space>
              <Col align="right">
                {totalRecords !== state.transactions.length ? (
                  <span style={{ marginTop: "5px" }}>
                    <b>
                      <i>
                        <Spin indicator={antIcon} />
                        Showing {state.transactions.length} of {totalRecords}
                      </i>
                    </b>{" "}
                  </span>
                ) : (
                  <span style={{ marginTop: "5px" }}>
                    <b>
                      <i>All {state.transactions.length} records</i>
                    </b>
                  </span>
                )}
              </Col>
              {totalRecords === state.transactions.length &&
              state.transactions.length > 0 ? (
                <>
                  <ExportExcel
                    data={state.transactions}
                    fileName={" TransactionDetails"}
                    country={filterState.country}
                  />
                  { userPermission.includes("W") ? (
                    <SaveAll
                    records={state.updatedRecords}
                    dispatch={dispatch}
                    filter={filterState}
                    tab="transactions"/>
                  ):(<></>)
                  }
                </>
              ) : (
                <></>
              )}
            </Space>
          </div>
        </Col>
      </Row>
      <Row>
        <div>
          {state.isFetching ? (
            <Spin tip="Loading...." />
          ) : (
            <Form form={form} component={false}>
              <Table
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                bordered
                dataSource={state.transactions}
                columns={mergedColumns}
                scroll={{ x: 4000, y: 515 }}
                size="small"
                rowClassName="editable-row"
                onChange={() => {
                  handleTableChange();
                }}
                pagination={{
                  defaultPageSize: 15,
                  showSizeChanger: true,
                  pageSizeOptions: ["15", "30", "50", "100"],
                }}
              />
            </Form>
          )}
        </div>
      </Row>
    </>
  );
};
