/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "antd/dist/antd.css";
import {
  Table,
  Input,
  Form,
  Button,
  Space,
  Spin,
  Tooltip,
  InputNumber,
  Popconfirm,
  Typography,
} from "antd";
import { Row, Col } from "react-bootstrap";
import Highlighter from "react-highlight-words";
import { SearchOutlined, LoadingOutlined } from "@ant-design/icons";
import { useContext } from "react";

import { TrialBalancesContext } from "../context/TrialBalanceContext";
import { FilterContext } from "../context/FilterContext";
import { ExportExcel } from "./ExportExcel";
import SaveAll from "./SaveAll";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: false,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
export const TrialBalanceTable = ({ setReportDate, setTBFlag, userPermission }) => {
  const { filterState } = useContext(FilterContext);
  const { state, dispatch } = useContext(TrialBalancesContext);
  // console.log("Trial Balance Data::", state);
  let data, columns, reportDate, totalRecords;
  columns = state.columns;
  reportDate = state.reportDate;
  setReportDate(reportDate);
  totalRecords = state.totalRecords;

  if (state.TrialBalances.length > 0) {
    data = state.TrialBalances;

    state.TrialBalances = data.map((record) => {
      return {
        key: record["Index"],
        Index: record["Index"],
        CoCd: record["CoCd"],
        Crcy: record["Crcy"],
        "DB Amount": record["DB Amount"],
        "G/L Account": record["G/L Account"],
        "G/L Acct Long Text": record["G/L Acct Long Text"],
        "TB in local Curr.": record["TB in local Curr."],
        "Weekly Dashboard": record["Weekly Dashboard"],
        "Balance Difference": record["Balance Difference"],
      };
    });
  }

  const [form] = Form.useForm();

  const [editingKey, setEditingKey] = useState("");

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      CoCd: "",
      Crcy: "",
      "DB Amount": "",
      "G/L Account": "",
      "G/L Acct Long Text": "",
      "TB in local Curr.": "",
      "Weekly Dashboard": "",
      "Balance Difference": "",
      ...record,
    });
    setEditingKey(record.key);
  };

  // console.log("edit key:::", editingKey);

  const cancel = () => {
    setEditingKey("");
  };

  const Save = async (record) => {
    try {
      const row = await form.validateFields();
      // console.log("row::", row);
      // console.log("record::", { ...record, ...row });
      dispatch({ type: "UPDATE", payload: { ...record, ...row } });
      // console.log("update records::", state.updatedRecords);
      setEditingKey("");
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  let [sstate, setState] = useState("");
  sstate = {
    searchText: "",
    searchedColumn: "",
  };

  let searchInput;
  let getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      sstate.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[sstate.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    // console.log("data index::", dataIndex);
    // console.log("selected key index::", selectedKeys);
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  }

  function handleReset(clearFilters) {
    clearFilters();
    setState({ searchText: "" });
  }

  let [filterData, setFilterData] = useState(null);
  const handleTableChange = (pagination, filters, sorter) => {
    // console.log("filters::", filters);
    setFilterData({
      filterData: filters,
    });
    // console.log("pagination::", pagination);
    // console.log("sorter::", sorter);
    // console.log("filter data::", filterData);
  };
  // console.log("TB Columns:",columns);
  if (!userPermission.includes("W") && columns.includes('Index')) {
    let keyItem = columns.indexOf('Index')
    columns.splice(keyItem,1)
    // console.log("TB column in if:", columns)
  }
  // console.log("TB Columns2:", columns);

  let tbColumnsList;
  if (!tbColumnsList) {
    if (columns && columns.length > 0) {
      tbColumnsList = columns.map((tbcolumn) => {
        let editColumnFlag = false;
        if (["DB Amount", "TB in local Curr."].includes(tbcolumn)) {
          editColumnFlag = true;
        } else {
          editColumnFlag = false;
        }
        if (["Balance Difference"].includes(tbcolumn)) {
          return {
            title: tbcolumn,
            dataIndex: tbcolumn,
            editable: editColumnFlag,
            align: "left",
            ellipsis: {
              showTitle: false,
            },
            filters: [
              { text: "Zero", value: 0 },
              { text: "Non Zero", value: 1 },
            ],
            onFilter: (value, record) => {
              // console.log("filter column::", filterData);
              // console.log("tb column::", tbcolumn);
              // console.log("value::", value);
              // console.log("record::", record);
              // console.log("record value::", record[tbcolumn]);
              if (value === 0) {
                return record[tbcolumn] === value || record[tbcolumn] === "-";
              } else if (value === 1) {
                return record[tbcolumn] !== 0 && record[tbcolumn] !== "-";
              }
            },
          };
        }
        if (["DB Amount", "TB in local Curr."].includes(tbcolumn)) {
          return {
            title: tbcolumn,
            dataIndex: tbcolumn,
            editable: editColumnFlag,
            align: "right",
            ...getColumnSearchProps(tbcolumn),
            ellipsis: {
              showTitle: false,
            },
            render(text, record) {
              <Tooltip placement="topLeft" title={text}>
                {text}
              </Tooltip>;
              return {
                props: {
                  style: { color: text.includes("-") ? "red" : "" },
                },
                children: <div>{text}</div>,
              };
            },
          };
        }
        return {
          title: tbcolumn,
          dataIndex: tbcolumn,
          editable: editColumnFlag,
          align: "left",
          ...getColumnSearchProps(tbcolumn),
          ellipsis: {
            showTitle: false,
          },
          render: (text) => (
            <Tooltip placement="topLeft" title={text}>
              {text}
            </Tooltip>
          ),
        };
      });
      if (userPermission.includes("W")) {
        tbColumnsList[0] = {
          title: "Operation",
          dataIndex: "Operation",
          fixed: "left",
          // #1890ff
          render: (_, record) => {
            const editable = isEditing(record);
            return editable ? (
              <span>
                <a
                  href="javascript:;"
                  onClick={() => Save(record)}
                  style={{
                    marginRight: 8,
                    color: "#1890ff",
                  }}
                >
                  Save
                </a>
                <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                  <a style={{ color: "#1890ff" }}>Cancel</a>
                </Popconfirm>
              </span>
            ) : (
              <Typography.Link
                disabled={editingKey !== ""}
                onClick={() => edit(record)}
              >
                Edit
              </Typography.Link>
            );
          },
        };
      } else {
        data = state.TrialBalances;
        delete data.Index
        state.TrialBalances = data
      }
    }
  }
  // console.log("tb column list::", tbColumnsList);
  const mergedColumns =
    tbColumnsList &&
    tbColumnsList.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
    });
  // console.log("data::::", data);
  if (totalRecords !== state.TrialBalances.length || state.isFetching) {
    setTBFlag(true);
  } else {
    setTBFlag(false);
  }

  const exportDataNumberFormatting = (TrialBalances) => {
    const updatedTrialBalances = TrialBalances.map(TrialBalance =>(
      {...TrialBalance,
      ["TB in local Curr."]: typeof(TrialBalance["TB in local Curr."])==="string"?(TrialBalance["TB in local Curr."].replace(/,/g, '')):(TrialBalance["TB in local Curr."]),
      ["DB Amount"]: typeof(TrialBalance["DB Amount"])==="string"?(parseFloat(TrialBalance["DB Amount"].replace(/,/g, ''))):(TrialBalance["DB Amount"]),
      }
    ))
    return updatedTrialBalances
  }

  return (
    <>
      <Row>
        <Col>
          {totalRecords !== state.TrialBalances.length ? (
            <p>
              <b>
                <i>
                  <Spin indicator={antIcon} />
                  Showing {state.TrialBalances.length} of {totalRecords}
                </i>
              </b>
            </p>
          ) : (
            <p>
              <b>
                <i>All {state.TrialBalances.length} records</i>
              </b>
            </p>
          )}
        </Col>
        <Col align="right">
          <Space>
            {totalRecords === state.TrialBalances.length &&
            state.TrialBalances.length > 0 ? (
              <>
                { userPermission.includes("W") ? (
                    <SaveAll
                    records={state.updatedRecords}
                    dispatch={dispatch}
                    filter={filterState}
                    tab="trialBal"/>
                  ):(<></>)
                }
                <ExportExcel
                  data={exportDataNumberFormatting(state.TrialBalances)}
                  fileName={" TrialBalances"}
                  country={filterState.country}
                />
              </>
            ) : (
              <></>
            )}
          </Space>
        </Col>
      </Row>
      <Row>
        <div>
          {state.isFetching ? (
            <Spin tip="Loading...." />
          ) : (
            <Form form={form} component={false}>
              <Table
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                bordered
                dataSource={state.TrialBalances}
                columns={mergedColumns}
                rowClassName="editable-row"
                onChange={() => {
                  handleTableChange();
                }}
                size="small"
                scroll={{ x: 1200, y: 515 }}
                pagination={{
                  defaultPageSize: 15,
                  showSizeChanger: true,
                  pageSizeOptions: ["15", "30", "50", "100"],
                }}
              />
            </Form>
          )}
        </div>
      </Row>
    </>
  );
};
