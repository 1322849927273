import React, { useState } from "react";
import { Container, Row } from "react-bootstrap";
import Footer from "./Footer/Footer";
import { TabPanel } from "../TabPanel";
import TopbarRouter from "../Layout/Topbar Router/index"
import { FilterProvider } from "../../context/FilterContext";

export default function Layout() {
  const [reportDate, setReportDate] = useState("");
  const [allrowsFlag, setAllRowsFlag] = useState(false);

  // console.log("layout report Date::", reportDate);
 
  return (
    <FilterProvider>
      <Container fluid>
          <Row>
            <TopbarRouter />
          </Row>
          <Row style={{ padding: "4px" }}>
            <TabPanel
              handleDate={setReportDate}
              fileDate={reportDate}
              handleRowsFlag={setAllRowsFlag}
              rowsFlag={allrowsFlag}
            />
          </Row>
          <Row>
            <Footer />
          </Row>
      </Container>
    </FilterProvider>
  );
}
