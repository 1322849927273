import React from "react";
import "./topbar.css";
import { LogoutOutlined } from "@ant-design/icons"
import { Row, Col, message, Tooltip } from "antd";
import logo from "./travelex-logo-old.svg";
import Cookies from "js-cookie";
import { logoutUser } from "../../../api/logout";

// console.log("PATH NAME ::::", window.location);

class Topbar extends React.Component {
    
    logout = async (e) => {
      try {

          let body = JSON.stringify({
              code: Cookies.get('code')
          })
          // console.log("Push logout :::", this.props);
          // console.log("Logout event :::", e);
          // console.log("Finance url :::", `${process.env.REACT_APP_FINANCE_URL}`);
          // console.log("Cookies code ::: ", body);
          const res = await logoutUser(body)

          if (res && res.message === "Token invalidated") {
              //clear cookies and push to login
              Cookies.remove('expiresIn');
              Cookies.remove('code');
              Cookies.remove('code_challenge');
              Cookies.remove('code_verifier');
              // console.log("code :::", Cookies.get('code'));
              // console.log("Cookies code After ::: ", body);
              // this.props.history.push("/");
              window.location.href = `${process.env.REACT_APP_FINANCE_URL}`;
          } else {
              message.error(res.message || 'Error Occured');
          }
      } catch (err) {
          console.log('Error Occured', err);
      }

  }
  
  render() {
    return (
      <Row
        style={{
          backgroundColor: "#e3f1fe",
          borderBottom: "1px solid rgba(28,34,67,.2901960784313726)",
        }}
      >
        <Col span={8}>
          <img
            alt=""
            src={logo}
            width="160"
            height="50"
            className="d-inline-block align-top"
          />
        </Col>
        <Col span={8}>
          <h4 style={{ paddingTop: "6px" }}>
            ANZ Finance Weekly Recs Dashboard
          </h4>
        </Col>
        <Col span={8}>
          {
            window.location.pathname === "/dashboard" ? <Tooltip placement="top" title={"Logout"}><LogoutOutlined style={{float:"right", paddingTop:"10px", height:"30px", width:"35px", fontSize: '28px'}} onClick={() => this.logout()} alt="Logout" /></Tooltip> : null
          }
        </Col>
      </Row>
    );
  }
}

export default Topbar;
