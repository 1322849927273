import React, { useContext } from "react";
import { Select } from "antd";
import { FilterContext } from "../../context/FilterContext";

// export const Country = createContext();
const countryObject={AU:"Australia", NZ:"New Zealand"}

export const FilterByCountry = ({ rowsFlag, countries }) => {
  const { filterState, filterDispatch } = useContext(FilterContext);
  // console.log(filterState);
  // console.log("rows flag:::", rowsFlag);
  // console.log("filter by countries :: ",countries);
  if (countries.length > 0 && filterState.country === ""){
    filterDispatch({ type: "COUNTRY_CHANGE", value: countries[0] })
  }
  return (
    <div key={countries.length !== 0 ? (countries[0]):(filterState.country)}>
    <Select
      defaultValue={countries.length !== 0 ? (countries[0]):(filterState.country)}
      style={{ width: 200 }}
      placeholder="Select Country"
      optionFilterProp="children"
      onChange={(e) => filterDispatch({ type: "COUNTRY_CHANGE", value: e })}
      disabled={rowsFlag}
    >
      {countries.map((country) => (
        <option key={country} value={country}>{countryObject[country]}</option>
      ))}
    </Select>
    </div>
  );
};
