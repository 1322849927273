/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "antd/dist/antd.css";
import {
  Table,
  Input,
  Form,
  Button,
  Space,
  Spin,
  Tooltip,
} from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { useContext } from "react";
import NumberFormat from "react-number-format";
import { SummaryContext } from "../context/SummaryContext";

export const SummaryTable = ({ setReportDate }) => {
  const { state } = useContext(SummaryContext);
  // console.log("Summary Data::", state);
  let transformedData, data, columns, reportDate;
  // console.log("data####", state.summary);
  // console.log("Summary length :: ", state.summary.length);

  if (state.summary.length > 0) {
    reportDate = state.summary[0]["Report Date"];
    setReportDate(reportDate);
    data = state.summary[0].data;
    columns = state.summary[0].columns;
    // console.log("summary data::", data);
    // console.log("summary columns::", columns);

    transformedData = data.map((record) => {
      record["key"] = record["index"];
      return record;
    });
  }

  let [searchState, setSearchState] = useState("");
  searchState = {
    searchText: "",
    searchedColumn: "",
  };

  let searchInput;
  let getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchState.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchState.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchState({ searchText: "" });
  }

  // console.log("transform Data", transformedData);
  // console.log("summary Columns:", columns);
  let tbColumnsList;
  if (columns && columns.length > 0) {
    tbColumnsList = columns.map((tbcolumn) => {
      if (
        [
          "Above 2 Years",
          "6M - 1Y",
          "3M - 6M",
          "46 - 90 Days",
          "15 - 45 Days",
          "8 - 14 Days",
          "BAU THRESHOLD",
          "1 - 7 Days",
          "Above 1 Year",
        ].includes(tbcolumn)
      ) {
        return {
          title: tbcolumn,
          dataIndex: tbcolumn,
          align: "center",
          // ...getColumnSearchProps(tbcolumn),
          ellipsis: {
            showTitle: false,
          },
          children: [
              {
                  title: "Items",
                  dataIndex: tbcolumn.concat('_items'),
                  key: tbcolumn.concat('_items'),
                  width: 70,
              },
              {
                  title: "Value",
                  dataIndex: tbcolumn.concat('_values'),
                  key: tbcolumn.concat('_values'),
                  width: 100,
                  render(text, records){
                    <Tooltip placement="topLeft" title={text}>
                      {text}
                    </Tooltip>;
                    return {
                      props: {
                        style: { color: parseInt(text) < 0 ? "red" : "" },
                      },
                      children: (
                        <div>
                          {
                            <NumberFormat
                              value={text}
                              displayType="text"
                              thousandSeparator={true}
                            />
                          }
                        </div>
                      ),
                    };
                  }
              },
              {
                title: "Abs Value",
                dataIndex: tbcolumn,
                key: tbcolumn,
                width: 100,
                render(text, records){
                  <Tooltip placement="topLeft" title={text}>
                    {text}
                  </Tooltip>;
                  return {
                    children: (
                      <div>
                        {
                          <NumberFormat
                            value={text}
                            displayType="text"
                            thousandSeparator={true}
                          />
                        }
                      </div>
                    ),
                  };
                }
              }

          ],
          render(text, record) {
            <Tooltip placement="topLeft" title={text}>
              {text}
            </Tooltip>;
            // return {
            //   props: {
            //     style: { color: parseInt(text) < 0 ? "red" : "" },
            //   },
            //   children: (
            //     <div>
            //       {
            //         <NumberFormat
            //           value={text}
            //           displayType="text"
            //           thousandSeparator={true}
            //         />
            //       }
            //     </div>
            //   ),
            // };
          },
        };
      }else if (["CP"].includes(tbcolumn)) {
        return {
          title: tbcolumn,
          dataIndex: tbcolumn,
          align: "right",
          width: 40,
          // ...getColumnSearchProps(tbcolumn),
          ellipsis: {
            showTitle: false,
          },
          render(text, record) {
            <Tooltip placement="topLeft" title={text}>
              {text}
            </Tooltip>;
            return {
              props: {
                style: { color: parseInt(text) < 0 ? "red" : "" },
              },
              children: (
                <div>
                  {
                    <NumberFormat
                      value={text}
                      displayType="text"
                      // thousandSeparator={true}
                    />
                  }
                </div>
              ),
            };
          },
        };
      }else if ([
        "Total",
        "ABS Value",
        "Cleared Items"
      ].includes(tbcolumn)){
        return {
        
          title: tbcolumn,
          dataIndex: tbcolumn,
          align: "left",
          ...getColumnSearchProps(tbcolumn),
          width: 100,
          ellipsis: {
            showTitle: false,
          },
          render(text, record) {
            <Tooltip placement="topLeft" title={text}>
              {text}
            </Tooltip>;
            return {
              props: {
                style: { color: parseInt(text) < 0 ? "red" : "" },
              },
              children: (
                <div>
                  {
                    <NumberFormat
                      value={text}
                      displayType="text"
                      thousandSeparator={true}
                    />
                  }
                </div>
              ),
            };
          },
        };
      }else {
        return {
          title: tbcolumn,
          dataIndex: tbcolumn,
          align: "left",
          ...getColumnSearchProps(tbcolumn),
          width: 100,
          fixed: "left",
          ellipsis: {
            showTitle: false,
          },
          render: (text) => (
            <Tooltip placement="topLeft" title={text}>
              {text}
            </Tooltip>
          ),
        };
      }
      
      
    });
  }
  // console.log("summary column list::", tbColumnsList);
  const [form] = Form.useForm();

  return (
    <>
      <div>
        {state.isFetching ? (
          <Spin tip="Loading...." />
        ) : (
          <Form form={form} component={false}>
            <Table
              components={{}}
              bordered
              dataSource={transformedData}
              columns={tbColumnsList}
              size="small"
              scroll={{ x: "max-content", y: 515 }}
              pagination={{
                defaultPageSize: 15,
                showSizeChanger: true,
                pageSizeOptions: ["15", "30", "50", "100"],
              }}
            />
          </Form>
        )}
      </div>
    </>
  );
};
