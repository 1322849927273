import React, { useContext } from "react";
import { DatePicker, Space } from "antd";
import { FilterContext } from "../../context/FilterContext";


export const FilterByBusinessDate = ({ rowsFlag }) => {
  const { filterState, filterDispatch } = useContext(FilterContext);
  // console.log(filterState);
  return (
    <Space direction="vertical">
      <DatePicker
        defaultValue={filterState.date}
        onChange={(e) => filterDispatch({ type: "DATE_CHANGE", value: e })}
        disabled={rowsFlag}
      />
    </Space>
  );
};
