import React from "react";
import "../Footer/footer.css";

export default function Footer() {
  let year = new Date().getFullYear()
  return (
    <div className="footer">
      Copyright © {year} Travelex India Limited and its licensors. All rights
      reserved.
    </div>
  );
}
