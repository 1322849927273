import React, { createContext, useReducer, useEffect, useContext } from "react";
import { message } from "antd";
import { FilterContext } from "./FilterContext";
import APICall from "../dal/api-call";

let INITIAL_STATE = {
  clearedItems: [],
  isFetching: true,
  isError: false,
};

let reducer = (state, action) => {
  switch (action.type) {
    case "FETCH":
      // console.log("action:", action);
      state.clearedItems.push(action.payload);
      // console.log(state);
      return {
        ...state,
        clearedItems: state.clearedItems,
        isFetching: false,
      };

    case "FETCHING":
      return {
        ...state,
        isFetching: true,
      };

    case "FETCH_ERROR":
      return {
        ...state,
        isError: false,
        isFetching: false,
        clearedItems: [],
      };
    case "RESET":
      return {
        ...state,
        isFetching: true,
        clearedItems: [],
      };
    default:
      return;
  }
};

export const ClearedItemsContext = createContext(INITIAL_STATE);

export const ClearedItemsProvider = (props) => {
  const { filterState, filterDispatch } = useContext(FilterContext);
  // console.log("Cleared Items filter state:::", filterState);
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  useEffect(async () => {
    dispatch({ type: "RESET" });
    
    let response = await APICall.request(`${process.env.REACT_APP_API_BASE_PATH}clearedItems?businessName=ALL&region=${filterState.country}&date=${filterState.businessDate}`);

    if (response.status === 200){
      dispatch({ type: "FETCH", payload: response.data });
    }
    else{
      console.log("error::", response.response.data);
      dispatch({ type: "FETCH_ERROR" });
      message.error(response.response.data.message);
    }
  }, [filterState.businessType, filterState.country, filterState.businessDate]);
  return (
    <ClearedItemsContext.Provider value={{ state, dispatch }}>
      {props.children}
    </ClearedItemsContext.Provider>
  );
};
