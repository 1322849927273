import React from 'react';
import { getRandomString, encryptStringWithSHA256, hashToBase64url } from "../utils/index"
import Cookies from 'js-cookie';
import { Button } from 'react-bootstrap';
import Back from "../Background.jpg";

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = { loginLink: `` };
    }

    async componentDidMount() {}

    async login() {
        const state = getRandomString();
        // console.log(`state value is ${state}`);
        //localStorage.setItem("state", state);
        Cookies.set('state', state)
        // Create PKCE code verifier
        const code_verifier = getRandomString();
        // console.log(`code_verifier value is ${code_verifier}`);
        //localStorage.setItem("code_verifier", code_verifier);
        Cookies.set('code_verifier', code_verifier)
        // Create code challenge
        const arrayHash = await encryptStringWithSHA256(code_verifier);
        const code_challenge = hashToBase64url(arrayHash);
        // console.log(`code_challenge value is ${code_challenge}`);
        //localStorage.setItem("code_challenge", code_challenge)
        Cookies.set('code_challenge', code_challenge)
        // Redirtect user-agent to /authorize endpoint
        const LOGIN_URL = process.env.REACT_APP_DOMAIN_NAME + "/oauth2/authorize?response_type=code&state=" + state + "&client_id=" + process.env.REACT_APP_APP_CLIENT_ID + "&redirect_uri=" + process.env.REACT_APP_REDIRECT_URI + "&scope=openid&code_challenge_method=S256&code_challenge=" + code_challenge;
        // console.log(`LOGIN_URL value is ${LOGIN_URL}`)
        window.location.href = LOGIN_URL;
        this.setState({
            loginLink :LOGIN_URL,
            code_challenge,
            state,
            code_verifier
        })
    }

    render() {
        return <>
            <div style={{backgroundImage:`url(${Back})`, height:'100vh', backgroundSize: 'cover',backgroundRepeat: 'no-repeat', backgroundPosition: "0 0"}}>
                <div
                    style={{
                        margin: 0,
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        msTransform: "translate(-50%, -50%)",
                        transform: "translate(-50%, -50%)"
                    }}
                >
                    <Button onClick= {() => this.login()}>Login</Button>
                </div>
            </div>
        </>
    }
}

export default Login;