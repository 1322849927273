import React, { createContext, useReducer, useEffect, useContext } from "react";
import { FilterContext } from "./FilterContext";
import { message } from "antd";
import APICall from "../dal/api-call";

let limit = 500;
let offset = 0;

let INITIAL_STATE = {
  transactions: [],
  isFetching: true,
  isError: false,
  totalRecords: 0,
  batchSize: 0,
  columns: [],
  updatedRecords: [],
  reportDate: "",
};

let reducer = (state, action) => {
  switch (action.type) {
    case "FETCH":
      state.transactions.push(...action.payload.data);
      state.columns = action.payload.columns;
      state.totalRecords = action.payload.totalRecords;
      state.batchSize = action.payload.batchSize;
      state.reportDate = action.payload["Report Date"];
      return {
        ...state,
        transactions: [...new Set(state.transactions)],
        totalRecords: state.totalRecords,
        isFetching: false,
      };

    case "FETCHING":
      return {
        ...state,
        isFetching: true,
      };
    case "UPDATE":
      state.transactions[action.payload.key] = action.payload;
      let updatedTrans = action.payload[action.key];
      state.updatedRecords.push(action.payload);
      return {
        ...state,
      };
    case "FETCH_ERROR":
      return {
        ...state,
        isError: true,
        isFetching: false,
        transactions: [],
      };
    case "RESET_UPDATERECORDS":
      return {
        ...state,
        updatedRecords: [],
      };
    case "RESET":
      return {
        ...state,
        isFetching: true,
        transactions: [],
        totalRecords: 0,
        batchSize: 0,
      };
    default:
      return;
  }
};

export const TransactionsContext = createContext(INITIAL_STATE);

export const TransactionsProvider = (props) => {
  const { filterState } = useContext(FilterContext);
  // console.log("Transactions filter state:::", filterState);
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  useEffect(() => {
    dispatch({ type: "RESET" });
    fetchData(filterState, dispatch);
  }, [filterState]);

  return (
    <TransactionsContext.Provider value={{ state, dispatch }}>
      {props.children}
    </TransactionsContext.Provider>
  );
};

const fetchData = async (filterState, dispatch) => {
  offset = 0;
  // console.log("off set::", offset);
  // console.log("limit::", limit);
  const response = await APICall.request(`${process.env.REACT_APP_API_BASE_PATH}transaction?businessName=ALL&region=${filterState.country}&date=${filterState.businessDate}&limit=${limit}&offset=${offset}`);
  if (response.status === 200){
    let result = response.data;
    // console.log("data:::", result);
    dispatch({ type: "FETCH", payload: result });
    // console.log("total records:::", result.totalRecords);
    // console.log("batch size:::", result.batchSize);
    let offsetCounter = offset;
    if (offset <= result.totalRecords) {
      // console.log("off set is less than total records");
      while (offsetCounter <= result.batchSize) {
        offsetCounter = offsetCounter === 0 ? offsetCounter + 1 : offsetCounter;
        offset = offsetCounter * limit;
        // console.log("offset::", offset);
        const response = await APICall.request(`${process.env.REACT_APP_API_BASE_PATH}transaction?businessName=ALL&region=${filterState.country}&date=${filterState.businessDate}&limit=${limit}&offset=${offset}`);

        if (response.status === 200){
          const result = response.data;
          dispatch({ type: "FETCH", payload: result });
          offsetCounter = offsetCounter + 1;
        }
      }
    }
    return result;
  }
  else{
    // console.log("error::", response.response.data);
    dispatch({ type: "FETCH_ERROR" });
    message.error(response.response.data.message);
  }
};
