/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "antd/dist/antd.css";
import {
  Table,
  Input,
  Form,
  Button,
  Space,
  Spin,
  Tooltip,
} from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { useContext } from "react";

import { ClearedItemsContext } from "../context/ClearedItemsContext";


export const ClearedItemsTable = ({ setReportDate }) => {
  const { state, dispatch } = useContext(ClearedItemsContext);
  // console.log("Cleared Items Data::", state);
  // console.log("Trial dispatch::", dispatch);
  let transformedData, data, columns, reportDate;

  // console.log("data####", state.clearedItems);
  if (state.clearedItems.length > 0) {
    reportDate = state.clearedItems[0]["Report Date"];
    setReportDate(reportDate);
    data = state.clearedItems[0].data;
    columns = state.clearedItems[0].columns;
    // console.log("TB data::", data);
    // console.log("TB columns::", columns);

    if (data.length > 0) {
      transformedData = data.map((record) => {
        return {
          key: record["index"],
          index: record["index"],
          Recs: record["Recs"],
          Buckets: record["Buckets"],
          "Retail/ Partners": record["Retail/ Partners"],
          Items: record["Items"],
          Value: record["Value"],
          "ABS Value": record["ABS Value"],
        };
      });
    }
  }

  let [sstate, setState] = useState("");
  sstate = {
    searchText: "",
    searchedColumn: "",
  };

  let searchInput;
  let getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      sstate.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[sstate.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  }

  function handleReset(clearFilters) {
    clearFilters();
    setState({ searchText: "" });
  }

  let [filterData, setFilterData] = useState(null);
  const handleTableChange = (pagination, filters, sorter) => {
    setFilterData({
      filterData: filters,
    });
    // console.log("pagination::", pagination);
    // console.log("filters::", filters);
    // console.log("sorter::", sorter);
    // console.log("filter data::", filterData);
  };

  // console.log("transform Data", transformedData);
  // console.log("TB Columns:", columns);
  let tbColumnsList;
  if (columns && columns.length > 0) {
    tbColumnsList = columns.map((tbcolumn) => {
      if (["Retail/ Partners"].includes(tbcolumn)) {
        return {
          title: tbcolumn,
          dataIndex: tbcolumn,
          align: "left",
          ellipsis: {
            showTitle: false,
          },
          filters: [
            { text: "ATM", value: "ATM" },
            { text: "RETAIL", value: "RETAIL" },
            { text: "PARTNERS", value: "PARTNERS" },
            { text: "OVERHEADS", value: "OVERHEADS" },
            { text: "OTHERS", value: "OTHERS" },
          ],
          onFilter: (value, record) => {
            // console.log("filter column::", filterData);
            // console.log("tb column::", tbcolumn);
            // console.log("value::", value);
            // console.log("record::", record);
            return record[tbcolumn].includes(value);
          },
          render(text, record) {
            <Tooltip placement="topLeft" title={text}>
              {text},
            </Tooltip>;
            return {
              props: {
                style: { color: parseInt(text) < 0 ? "red" : "" },
              },
              children: <div>{text}</div>,
            };
          },
        };
      }
      if (["Value"].includes(tbcolumn)) {
        return {
          title: tbcolumn,
          dataIndex: tbcolumn,
          align: "right",
          ...getColumnSearchProps(tbcolumn),
          ellipsis: {
            showTitle: false,
          },
          render(text, record) {
            <Tooltip placement="topLeft" title={text}>
              {text}
            </Tooltip>;
            return {
              props: {
                style: { color: parseInt(text) < 0 ? "red" : "" },
              },
              children: <div>{text}</div>,
            };
          },
        };
      }
      return {
        title: tbcolumn,
        dataIndex: tbcolumn,
        align: "right",
        ...getColumnSearchProps(tbcolumn),
        ellipsis: {
          showTitle: false,
        },
        render: (text) => (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        ),
      };
    });
  }
  // console.log("tb column list::", tbColumnsList);
  const [form] = Form.useForm();
  return (
    <>
      {state.isFetching ? (
        <Spin tip="Loading...." />
      ) : (
        <Form form={form} component={false}>
          <Table
            components={{}}
            bordered
            size="small"
            dataSource={transformedData}
            columns={tbColumnsList}
            scroll={{ x: 1200, y: 400 }}
            onChange={() => {
              handleTableChange();
            }}
          />
        </Form>
      )}
    </>
  );
};
