import Swal from 'sweetalert2';
import { getRandomString, encryptStringWithSHA256, hashToBase64url } from "../utils/index"
import Cookies from 'js-cookie';
import {sleep} from '../utils/index'
import axios from "axios";
const API_RETRY_DELAY = 1000;
class APICall {

    async request(URL, requestOptions = {}) {
        // console.log("API CALL Started!!!", URL)
        let code = Cookies.get('code');
        // code = "1234"
        // console.log("API CALL CODE :::", code);

        if (code) {
            
                try {
                    // Setting headers
                    let headers = {
                        "code": Cookies.get('code'),
                        'code_verifier': Cookies.get('code_verifier'),
                        'applicationId': process.env.REACT_APP_APPLICATION_ID,
                        'x-origin-refer': process.env.REACT_APP_X_ORIGIN_REFER
                    };
                    if (requestOptions.headers) {
                        requestOptions.headers = { ...requestOptions.headers, ...headers }
                    }
                    else
                        requestOptions.headers = headers;
                    
                    // console.log("API CALL request options :::", requestOptions);
                    // console.log("Calling fetch!!!");
                    // console.log("API CALL URL :::", URL);
                    let response = await axios.get(URL, {
                        headers: requestOptions.headers
                    });
                    // console.log("API CALL result ::: ", response);
                    return response;
                }
                catch (e) {
                    // console.log("Error API Call :::", e.response);
                    // throw e;
                    return e;
                }
           
        }
        else {
            this.sweetAlert();
        }
    }

    async postRequest(URL, requestOptions = {}) {
        let code = Cookies.get('code');
        code = "1234";

        if (code) {
            return new Promise(async (resolve, reject) => {
                try {
                    // Setting headers
                    let headers = {
                        "code": Cookies.get('code'),
                        'code_verifier': Cookies.get('code_verifier'),
                        'applicationId': process.env.REACT_APP_APPLICATION_ID,
                        'x-origin-refer': process.env.REACT_APP_X_ORIGIN_REFER
                    };
                    if (requestOptions.headers) {
                        requestOptions.headers = { ...requestOptions.headers, ...headers }
                    }
                    else
                        requestOptions.headers = headers;

                    let retryCountLength = 3;
                    for (let retryCount = 0; retryCount <= retryCountLength; retryCount++) {
                        let response = await fetch(URL, requestOptions);
                        if (response.status === 200) {
                            resolve(await response.json());
                            break;
                        }
                       await sleep(API_RETRY_DELAY);
                    }
                    reject('Retry count exceed');
                }
                catch (e) {
                    throw e;
                }
            });
        }
        else {
            this.sweetAlert();
        }
    }

    async requestIdentity(URL, requestOptions = {}) {
        // console.log("API CALL Started!!!", URL)
        let code = Cookies.get('code');
        // code = "1234"
        // console.log("API CALL CODE :::", code);

        if (code) {
            
                try {
                    // Setting headers
                    let headers = {
                        "code": Cookies.get('code'),
                        'code_verifier': Cookies.get('code_verifier'),
                        'applicationId': process.env.REACT_APP_APPLICATION_ID,
                        'x-origin-refer': process.env.REACT_APP_X_ORIGIN_REFER
                    };
                    if (requestOptions.headers) {
                        requestOptions.headers = { ...requestOptions.headers, ...headers }
                    }
                    else
                        requestOptions.headers = headers;
                    
                    let response, retryCountLength = 3, statusCode = 0;
                    for (let count = 1; count <= 3; count++) {
                        // console.log("request for loop , ", count);
                        try {
                            response = await axios.get(URL, { headers: requestOptions.headers });
                            // console.log("Test 2 :: ", response.status);
                            // console.log("Test 3 :: ", response)
                            statusCode = response.status;
                            break;
                        } catch (ex) {
                            await sleep(API_RETRY_DELAY);
                            if (retryCountLength <= count) {
                                // console.log('retrying attempt', count, " --- ", ex, " --- ", ex.status)
                                if(ex.status === 400){
                                    response = ex.response;
                                    statusCode = ex.status;
                                    break;
                                }
                            } else {
                                response = ex.response;
                                statusCode = ex.status;
                                // return response
                            }
                        }
                    }
                    return response;
                }
                catch (e) {
                    // console.log("Error API Call :::", e.response);
                    // throw e;
                    return e;
                }
           
        }
        else {
            this.sweetAlert();
        }
    }


    sweetAlert() {
        Swal.fire({
            title: 'Session has expired',
            text: 'Redirecting to the login page',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel'
        }).then((result) => {
            if (result.isConfirmed) {
                window.location.href = `/`;
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                // window.location.href = `/login`;
            }
        });
    }

    getLoginURL = async () => {
        const state = getRandomString();
        // console.log(`state value is ${state}`);
        //localStorage.setItem("state", state);
        Cookies.set('state', state)
        // Create PKCE code verifier
        const code_verifier = getRandomString();
        // console.log(`code_verifier value is ${code_verifier}`);
        //localStorage.setItem("code_verifier", code_verifier);
        Cookies.set('code_verifier', code_verifier)
        // Create code challenge
        const arrayHash = await encryptStringWithSHA256(code_verifier);
        const code_challenge = hashToBase64url(arrayHash);
        // console.log(`code_challenge value is ${code_challenge}`);
        //localStorage.setItem("code_challenge", code_challenge)
        Cookies.set('code_challenge', code_challenge)
        // Redirtect user-agent to /authorize endpoint
        const LOGIN_URL = process.env.REACT_APP_DOMAIN_NAME + "/oauth2/authorize?response_type=code&state=" + state + "&client_id=" + process.env.REACT_APP_APP_CLIENT_ID + "&redirect_uri=" + process.env.REACT_APP_REDIRECT_URI + "&scope=openid&code_challenge_method=S256&code_challenge=" + code_challenge;
        // console.log(`LOGIN_URL value is ${LOGIN_URL}`)

        return LOGIN_URL;
    }
}
export default new APICall();