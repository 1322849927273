import React, { useContext } from "react";
import { Radio } from "antd";

export const FilterByBusinessType = ({ tab, context }) => {
  const { state, dispatch } = useContext(context);
  if (tab === "Charts") {
    return getBusinessType(state, dispatch);
  } else if (tab === "Summary") {
    return getBusinessType(state, dispatch);
  }
  // console.log(state);

  function getBusinessType(state, dispatch) {
    const options = [
      { label: "ALL", value: "ALL" },
      { label: "RETAIL", value: "RETAIL" },
      { label: "PARTNERS", value: "PARTNERS" },
      { label: "OVERHEADS", value: "OVERHEADS" },
      { label: "OTHERS", value: "OTHERS" },
    ];
    return (
      <Radio.Group
        size="small"
        options={options}
        value={state.businessType}
        onChange={(e) => dispatch({ type: "BUSINESSTYPE_CHANGE", value: e })}
        optionType="button"
        buttonStyle="solid"
        style={{ paddingBottom: "4px" }}
      />
    );
  }
};
