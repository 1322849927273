// import React from "react";
// import ReactDOM from "react-dom";
import { message, Button } from "antd";
import axios from "axios";
import { useState } from "react";
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';

const sweetAlert = () => {
  Swal.fire({
      title: 'Session has expired',
      text: 'Redirecting to the login page',
      icon: 'warning',
      showCancelButton: false,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Cancel'
  }).then((result) => {
      if (result.isConfirmed) {
          window.location.href = `/`;
      } else if (result.dismiss === Swal.DismissReason.cancel) {
          // window.location.href = `/login`;
      }
  });
}

export const UploadFile = ({ country, businessDate }) => {
  const fileRegex = `^${country} TransactionDetails.(XLSX|xlsx)$`;
  let [fileTypeFlag, setFileTypeFlag] = useState(false);
  const [fileToUpload, setFileToUpload] = useState("");
  const [loading, setLoading] = useState(false);
  let [fileUploaded, setFileUploaded] = useState(false);

  const checkFileName = (e) => {
    // console.log("event::", e);
    setFileToUpload(e.target.files[0]);
    setFileUploaded(false);
    if (e.target.files.length > 0) {
      let regex = new RegExp(fileRegex);
      if (!regex.test(e.target.files[0].name)) {
        setFileTypeFlag(false);
        message.error("Incorrect File Type or File Name");
      } else {
        setFileTypeFlag(true);
      }
    }
    return fileToUpload;
  };

  const uploadFile = () => {
    // console.log("filter country:::", country);
    // console.log("filter business date:::", businessDate);
    // Getting the signed url
    setLoading(true);
    // console.log("file name:::", fileToUpload.name);

    let code = Cookies.get('code');

    if (code) {
      let headers = {
        "code": Cookies.get('code'),
        'code_verifier': Cookies.get('code_verifier'),
        'applicationId': 'finance-auth-ui',//process.env.REACT_APP_APPLICATION_ID
        'x-origin-refer': process.env.REACT_APP_X_ORIGIN_REFER
      };

      axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_BASE_PATH}upload?region=${country}&date=${businessDate}`,
        headers: headers,

      }).then((response) => {
        // Getting the url from response
        const url = response.data.url;
  
        axios({
          method: "PUT",
          url: url,
          data: fileToUpload,
          headers: { 
            "code": Cookies.get('code'),
            'code_verifier': Cookies.get('code_verifier'),
            'applicationId': 'finance-auth-ui',//process.env.REACT_APP_APPLICATION_ID,
            'x-origin-refer': process.env.REACT_APP_X_ORIGIN_REFER,
            "Content-Type": "multipart/form-data" },
        })
          .then((res) => {
            message.success(
              `${fileToUpload} File uploaded Successfully. Please refresh the page`
            );
            setLoading(false);
            setFileUploaded(true);
          })
          .catch((err) => {
            message.error(`Error Occured while uploading the file: ${err}`);
            setLoading(false);
          });
      });
    }
    else {
      sweetAlert();
      // console.log("Upload.js code is not available !!");
    }

    // axios(
    //   `${process.env.REACT_APP_API_BASE_PATH}upload?region=${country}&date=${businessDate}`
    // ).then((response) => {
    //   // Getting the url from response
    //   const url = response.data.url;

    //   axios({
    //     method: "PUT",
    //     url: url,
    //     data: fileToUpload,
    //     headers: { "Content-Type": "multipart/form-data" },
    //   })
    //     .then((res) => {
    //       message.success(
    //         `${fileToUpload} File uploaded Successfully. Please refresh the page`
    //       );
    //       setLoading(false);
    //       setFileUploaded(true);
    //     })
    //     .catch((err) => {
    //       message.error(`Error Occured while uploading the file: ${err}`);
    //       setLoading(false);
    //     });
    // });
  };
  return (
    <div>
      <form>
        <div className="form-group">
          <input
            type="file"
            className="form-control-file"
            id="fileUpload"
            onChange={(e) => checkFileName(e)}
          />
          {fileToUpload && fileTypeFlag && !fileUploaded ? (
            <Button
              size="small"
              type="primary"
              loading={loading}
              onClick={(e) => {
                uploadFile();
              }}
            >
              Upload your file
            </Button>
          ) : null}
        </div>
      </form>
    </div>
  );
};
