/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect } from "react";
import "antd/dist/antd.css";
import { Tabs, Row, Col } from "antd";
import "bootstrap/dist/css/bootstrap.min.css";
import { TransactionsTable } from "./Transactions";
import { TrialBalanceTable } from "./TrailBalance";
import { ClearedItemsTable } from "./ClearedItems";
import { SummaryTable } from "./Summary";
import { TrialBalancesProvider } from "../../src/context/TrialBalanceContext";
import { ClearedItemsProvider } from "../../src/context/ClearedItemsContext";
import { TransactionsProvider } from "../../src/context/TransactionsContext";
import { SummaryProvider } from "../../src/context/SummaryContext";
import { FilterByCategory } from "./filters/FilterByCategory";
import { FilterByBusinessType } from "./filters/FilterByBusinessType";
import { SummaryContext } from "../../src/context/SummaryContext";
import { FilterByCountry } from "./filters/FilterByCountry";
import { FilterByBusinessDate } from "./filters/FilterByBusinessDate";
import Cookies from 'js-cookie';
import dal from "../../src/dal/api-call";
import Swal from 'sweetalert2';

const { TabPane } = Tabs;

const callback = (key) => {
  console.log("callback key :: ",key);
};

let countryList = [];
let permissionList = [];
export const TabPanel = ({
  handleDate,
  fileDate,
  handleRowsFlag,
  rowsFlag,
}) => {

  let code = Cookies.get('code');
  const [countries, setCountries] = useState([]);
  const [permission, setPermission] = useState([]);
  const [userIdentity , setUserIdentity] = useState(null);

  useEffect(() => {
    if(code){
        if(!userIdentity){
            dal.requestIdentity(`${process.env.REACT_APP_API_BASE_PATH}v1/user/identity`)
                .then((response) => {
                    // console.log("Auth response :: ", response)
                    // console.log("AUth response data :: ",response.data)
                    // set user identity as data of user 
                    setUserIdentity(JSON.parse(JSON.stringify(response.data)));
                    // console.log("UserIdentity :: ",userIdentity)
                })
                .catch((error) => console.log(error));
        }
        if(userIdentity && countries.length === 0 && permission.length === 0){
            // console.log("UserIdentity :: ",userIdentity)
            countryList = Object.values(userIdentity.geo)
            setCountries(countryList);
            permissionList = Object.values(userIdentity.permissions)
            setPermission(permissionList);
        }
    }else{
        sweetAlert();
    }
  }, [userIdentity])

  // console.log("rows flag###", rowsFlag);
  const [position, setPosition] = useState(["left"]);
  let reportDate = fileDate ? (
    <>
      <h6>Report Date: {fileDate}</h6>
    </>
  ) : (
    ""
  );
  const tabExtraContent = (
    <>
      <div style={{ paddingTop: "4px" }}>{reportDate}</div>
      <div style={{ paddingTop: "4px" }}>
        <FilterByBusinessDate rowsFlag={rowsFlag} />
      </div>
      <div style={{ paddingTop: "4px", paddingBottom: "4px" }}>
        <FilterByCountry rowsFlag={rowsFlag} countries={countries} />
      </div>
    </>
  );

  const OperationsSlot = {
    left: tabExtraContent,
  };

  const slot = useMemo(() => {
    if (position.length === 0) return null;

    return position.reduce(
      (acc, direction) => ({ ...acc, [direction]: OperationsSlot[direction] }),
      {}
    );
  }, [OperationsSlot, position, countries]);
  // console.log("slot::", slot);
  // console.log("recs report date::", fileDate);

  function sweetAlert() {
    Swal.fire({
        title: 'Session has expired',
        text: 'Redirecting to the login page',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel'
    }).then((result) => {
        if (result.isConfirmed) {
            window.location.href = '/';
        } else if (result.dismiss === Swal.DismissReason.cancel) {
            // window.location.href = '/login';
        }
    });
  }

  return (
    <>
      <Tabs
        type="card"
        defaultActiveKey="1"
        onChange={callback}
        style={{ paddingTop: "4px", paddingLeft: "4px", paddingRight: "10px" }}
        tabPosition="left"
        size="small"
        tabBarExtraContent={slot}
      >
        <TabPane tab="Summary" key="1">
          <SummaryProvider>
            <Row>
              <Col span={18}>
                <FilterByBusinessType tab="Summary" context={SummaryContext} />
              </Col>
              {/* <Col span={6} align="right">
                <FilterByCategory />
              </Col> */}
            </Row>
            <SummaryTable setReportDate={handleDate} />
          </SummaryProvider>
        </TabPane>
        <TabPane tab="Transaction Details" key="2">
          <TransactionsProvider>
            <TransactionsTable setReportDate={handleDate} userPermission={permission} />
          </TransactionsProvider>
        </TabPane>
        <TabPane tab="Trial VS DB Balance" key="3">
          <TrialBalancesProvider>
            <TrialBalanceTable
              setReportDate={handleDate}
              setTBFlag={handleRowsFlag}
              userPermission={permission}
            />
          </TrialBalancesProvider>
        </TabPane>
        <TabPane tab="Cleared Items" key="4">
          <ClearedItemsProvider>
            <ClearedItemsTable setReportDate={handleDate} />
          </ClearedItemsProvider>
        </TabPane>
      </Tabs>
    </>
  );
};
