import React, { useState } from "react";
import { message, Button } from "antd";
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';


const postFetchTransApiHandler = (data, filter) => {
  // console.log("post endpoint data::", data);

  let code = Cookies.get('code');
  if (code) {
    let requestOptions = {
      method: "POST",
      headers: {
        "code": Cookies.get('code'),
        'code_verifier': Cookies.get('code_verifier'),
        'applicationId': process.env.REACT_APP_APPLICATION_ID,
        "Content-Type": "application/json",
        "x-origin-refer": process.env.REACT_APP_X_ORIGIN_REFER
      },
      body: JSON.stringify({ data: data }),
    };

    try {
      let pdata = fetch(
        `${process.env.REACT_APP_API_BASE_PATH}updateTransaction?region=${filter.country}&date=${filter.businessDate}`,
        requestOptions
      )
        .then((Response) => Response.json())
        .then((data) => data);
      // console.log("pdata::::", pdata);
      return pdata;
    }
    catch (error) {
      console.error(error);
      message.error("error while posting records");
    }
  }
  else {
    sweetAlert();
  }
};

const postFetchTrialBalApiHandler = (data, filter) => {
  // console.log("post endpoint data::", data);

  let code = Cookies.get('code');
  if (code) {
    let requestOptions = {
      method: "POST",
      headers: {
        "code": Cookies.get('code'),
        'code_verifier': Cookies.get('code_verifier'),
        'applicationId': process.env.REACT_APP_APPLICATION_ID,
        "Content-Type": "application/json",
        "x-origin-refer": process.env.REACT_APP_X_ORIGIN_REFER
      },
      body: JSON.stringify({ data: data }),
    };

    try {
      let pdata = fetch(
        `${process.env.REACT_APP_API_BASE_PATH}updateBalance?region=${filter.country}&date=${filter.businessDate}`,
        requestOptions
      )
        .then((Response) => Response.json())
        .then((data) => data);
      // console.log("pdata::::", pdata);
      return pdata;
    }
    catch (error) {
      console.error(error);
      message.error("error while posting records");
    }
  }
  else {
    sweetAlert();
  }
};

const sweetAlert = () => {
  Swal.fire({
    title: 'Session has expired',
    text: 'Redirecting to the login page',
    icon: 'warning',
    showCancelButton: false,
    confirmButtonText: 'Ok',
    cancelButtonText: 'Cancel'
  }).then((result) => {
    if (result.isConfirmed) {
      window.location.href = `/`;
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      // window.location.href = `/login`;
    }
  });
}

export default function SaveAll({ records, dispatch, filter, tab }) {
  const [loading, setLoading] = useState(false);
  // console.log("Update Records", records);
  const saveHandler = (records, tab) => {
    setLoading(true);
    // console.log("Hit Save", records);
    if (records.length > 0) {
      if (tab === "transactions") {
        postFetchTransApiHandler(records, filter).then((data) => {
          // console.log("post data:", data);
          setLoading(false);
          message.success(
            `${records.length} records have been posted successfully`
          );
          dispatch({ type: "RESET_UPDATERECORDS" });
        });
      } else if (tab === "trialBal") {
        postFetchTrialBalApiHandler(records, filter).then((data) => {
          // console.log("post data:", data);
          setLoading(false);
          message.success(
            `${records.length} records have been posted successfully`
          );
          dispatch({ type: "RESET_UPDATERECORDS" });
        });
      }
    } else {
      setLoading(false);
      message.error("No records to save");
    }
  };
  return (
    <div>
      <Button
        size="small"
        type="primary"
        loading={loading}
        onClick={() => {
          saveHandler(records, tab);
        }}
      >
        Save All
      </Button>
    </div>
  );
}
