import React, { useReducer } from "react";
import moment from "moment";

let date = moment();
let businessDate = date.format("YYYY-MM-DD");

const initialState = {
  country: "",
  date: date,
  businessDate: businessDate,
  reportDate: businessDate,
};

let reducer = (state, action) => {
  switch (action.type) {
    case "DATE_CHANGE":
      return {
        ...state,
        date: action.value,
        businessDate: action.value.format("YYYY-MM-DD"),
      };

    case "COUNTRY_CHANGE":
      return {
        ...state,
        country: action.value,
      };

    default:
      return;
  }
};

export const FilterContext = React.createContext(initialState);

export const FilterProvider = (props) => {
  const [filterState, filterDispatch] = useReducer(reducer, initialState);
  return (
    <FilterContext.Provider value={{ filterState, filterDispatch }}>
      {props.children}
    </FilterContext.Provider>
  );
};
