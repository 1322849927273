import React from "react";
import { Button } from "antd";

import * as FileSaver from "file-saver";

import * as XLSX from "xlsx";

export const ExportExcel = ({ data, fileName, country }) => {
  fileName = country + fileName;
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  const fileExtension = ".xlsx";
  let transData = JSON.parse(JSON.stringify(data));
  // console.log(transData === data);
  transData.forEach((record) => {
    delete record.key;
  });
  // console.log("state data###::", data);
  // console.log("trans data###::", transData);

  const addTextFormat = (ws, formattingColumnList, numberFormat) => {
    let range = XLSX.utils.decode_range(ws["!ref"]);
    for(let R = range.s.r + 1; R <= range.e.r; ++R) {
      for (var c = 0 ; c <= formattingColumnList.length; c++){
        let cell = ws[XLSX.utils.encode_cell({r:R,c:formattingColumnList[c]})];
        if(!cell) continue;
        cell.t = 'n';
        cell.z = numberFormat;
      }
    }
  }

  const exportToCSV = (data, fileName) => {
    const ws = XLSX.utils.json_to_sheet(data);
    if(fileName===`${country} TrialBalances`){addTextFormat(ws,[3,6],"#,##0.00")}
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const fileData = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(fileData, fileName + fileExtension);
  };

  return (
    <Button
      type="primary"
      size="small"
      onClick={(e) => exportToCSV(transData, fileName)}>
      Export
    </Button>
  );
};
