import React, { createContext, useReducer, useEffect, useContext } from "react";
import { message } from "antd";
import { FilterContext } from "./FilterContext";
import APICall from "../dal/api-call";
import {sleep} from '../utils/index'

const API_RETRY_DELAY = 1000;
let INITIAL_STATE = {
  summary: [],
  isFetching: true,
  isError: false,
  category: "ABS Value",
  businessType: "ALL",
};

let reducer = (state, action) => {
  switch (action.type) {
    case "FETCH":
      // console.log("action:", action);
      state.summary.push(action.payload);
      // console.log(state);
      return {
        ...state,
        summary: state.summary,
        isFetching: false,
      };

    case "FETCHING":
      return {
        ...state,
        isFetching: true,
      };

    case "FETCH_ERROR":
      return {
        ...state,
        isError: false,
        isFetching: false,
        summary: [],
      };
    case "RESET":
      return {
        ...state,
        isFetching: true,
        summary: [],
      };
    case "CATEGORY_CHANGE":
      // console.log("Category type:", action.value.target.value);
      return {
        ...state,
        category: action.value.target.value,
      };
    case "BUSINESSTYPE_CHANGE":
      // console.log("business type:", action.value.target.value);
      return {
        ...state,
        businessType: action.value.target.value,
      };
    default:
      return;
  }
};

export const SummaryContext = createContext(INITIAL_STATE);

export const SummaryProvider = (props) => {
  const { filterState, filterDispatch } = useContext(FilterContext);
  // console.log("Summary filter state:::", filterState);
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  let response;
  useEffect(async () => {
    dispatch({ type: "RESET" });
    // console.log("Calling API CALL")
    while (filterState.country === "") {
      await sleep(API_RETRY_DELAY);
    }
    response = await APICall.request(`${process.env.REACT_APP_API_BASE_PATH}summary?businessName=${state.businessType}&region=${filterState.country}&date=${filterState.businessDate}`)

    if (response.status === 200){
      dispatch({ type: "FETCH", payload: response.data });
    }
    else{
      // console.log("error::", response.response.data);
      dispatch({ type: "FETCH_ERROR" });
      message.error(response.response.data.message);
    }
  }, [
    state.businessType,
    filterState.country,
    filterState.businessDate,
    // state.category,
  ]);
  return (
    <SummaryContext.Provider value={{ state, dispatch }}>
      {props.children}
    </SummaryContext.Provider>
  );
};
