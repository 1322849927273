import React from "react";
import Topbar from "./../Topbar";
import Login from '../../../views/login';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

export default function TopbarRouter() {
  return (
        <Router>
          <Switch>
            <Route path="/dashboard">
              <Topbar />
            </Route>
            <Route path="/">
              <Login />
            </Route>
          </Switch>
        </Router>
  );
}
