/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useReducer, useEffect, useContext } from "react";
import { message } from "antd";

import { FilterContext } from "./FilterContext";
import APICall from "../dal/api-call";

let limit = 500;
let offset = 0;

let INITIAL_STATE = {
  TrialBalances: [],
  isFetching: true,
  isError: false,
  totalRecords: 0,
  batchSize: 0,
  columns: [],
  reportDate: "",
  updatedRecords: [],
};

let reducer = (state, action) => {
  switch (action.type) {
    case "FETCH":
      // console.log("action:", action);
      state.TrialBalances.push(...action.payload.data);
      state.columns = action.payload.columns;
      state.totalRecords = action.payload.totalRecords;
      state.batchSize = action.payload.batchSize;
      state.reportDate = action.payload["Report Date"];
      return {
        ...state,
        TrialBalances: [...new Set(state.TrialBalances)],
        totalRecords: state.totalRecords,
        isFetching: false,
      };

    case "FETCHING":
      return {
        ...state,
        isFetching: true,
      };
    case "UPDATE":
      state.TrialBalances[action.payload.key] = action.payload;
      let updatedTrans = action.payload[action.key];
      state.updatedRecords.push(action.payload);
      return {
        ...state,
      };
    case "FETCH_ERROR":
      return {
        ...state,
        isError: false,
        isFetching: false,
      };
    case "RESET_UPDATERECORDS":
      return {
        ...state,
        updatedRecords: [],
      };
    case "RESET":
      return {
        ...state,
        isFetching: true,
        TrialBalances: [],
        totalRecords: 0,
        batchSize: 0,
      };
    default:
      return;
  }
};

export const TrialBalancesContext = createContext(INITIAL_STATE);

export const TrialBalancesProvider = (props) => {
  const { filterState } = useContext(FilterContext);
  // console.log("Trail Balance filter state:::", filterState);
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  useEffect(() => {
    dispatch({ type: "RESET" });
    fetchData(filterState, dispatch);
  }, [filterState]);
  return (
    <TrialBalancesContext.Provider value={{ state, dispatch }}>
      {props.children}
    </TrialBalancesContext.Provider>
  );
};

const fetchData = async (filter, dispatch) => {
  offset = 0;
  // console.log("off set::", offset);
  // console.log("limit::", limit);
  const response = await APICall.request(`${process.env.REACT_APP_API_BASE_PATH}balance?region=${filter.country}&date=${filter.businessDate}&limit=${limit}&offset=${offset}`);

  if (response.status === 200){

    let result = response.data;
    // console.log("data:::", result);
    dispatch({ type: "FETCH", payload: result });
    // console.log("total records:::", result.totalRecords);
    // console.log("batch size:::", result.batchSize);
    let offsetCounter = offset;
    if (offset <= result.totalRecords) {
      // console.log("off set is less than total records");
      while (offsetCounter <= result.batchSize) {
        offsetCounter = offsetCounter === 0 ? offsetCounter + 1 : offsetCounter;
        offset = offsetCounter * limit;
        // console.log("offset::", offset);
        const response = await APICall.request(`${process.env.REACT_APP_API_BASE_PATH}balance?region=${filter.country}&date=${filter.businessDate}&limit=${limit}&offset=${offset}`);

        if (response.status === 200){
          const result = response.data;
          dispatch({ type: "FETCH", payload: result });
          offsetCounter = offsetCounter + 1;
        }
      }
    }
    return result;
  }
  else{
    // console.log("error::", response.response.data);
    dispatch({ type: "FETCH_ERROR" });
    message.error(response.response.data.message);
  }
};
